import React, { useState, useEffect } from "react";
import MaterialTable from "@material-table/core";
import { MuiThemeProvider } from "@material-ui/core";

import MetaTags from "../../components/metatags/MetaTags";
import PatchedPagination from "../../components/patchedpagination/PatchedPagination";
import { tableTheme } from "../../templates/themes";
import { ExportCsvTable, ExportPdfTable } from "./ExportFile";
import { ColumnFieldsCA } from "./data/DataCA";
import ColumnsCA from "./columns/ColumnsCA";
import TableAction from "./TableAction";
import { 
  FILTER_ROW_ID, ICONS_TOOLTIP,
  initializeFilterRow, handleFilterClick,
  CustomAddButton, CustomExportButton
} from "./CustomIcons";

import getCA from "../../utils/api/CA/getCA";
import addCA from "../../utils/api/CA/addCA";
import updateCA from "../../utils/api/CA/updateCA";
import deleteCA from "../../utils/api/CA/deleteCA";

import "./Table.css";

const COMMUNICATION_ACTIVITIES_ID = "communication-activities";

export default function CommunicationActivities() {
  const [table, setTable] = useState({
    columns: [],
    data: [],
    isLoading: true
  });

  const getTableData = async () => {
    const tableData = await getCA.getCA();

    setTable({
      columns: ColumnsCA,
      data: tableData,
      isLoading: false,
    });
  };

  const AddTableRow = async (newData, resolve) => {
    const rowData = await addCA.addImage(newData, newData.photo?.file);
    newData.id = rowData.id;
    
    resolve();

    setTable((prevState) => {
      const data = [...prevState.data];
      data.push(rowData);
      return { ...prevState, data };
    });
  };

  const UpdateTableRow = async (newData, oldData, resolve) => {
    if (oldData) {
      let updatedData;

      // checks if photo field was changed
      if (oldData.photo?.id === newData.photo?.id) {
        updatedData = await updateCA.updateCA(oldData, newData, newData.photo.id);
      } else {
        updatedData = await updateCA.update(oldData, newData, newData.photo?.file);
      }

      resolve();

      if (updatedData) {
        setTable((prevState) => {
          const data = [...prevState.data];
          data[data.indexOf(oldData)] = updatedData;
          return { ...prevState, data };
        });
      }
    }
  };

  const DeleteTableRow = async (oldData, resolve) => {

    if (oldData.photo?.file) {
      await deleteCA.deleteFileImage(oldData.photo?.file); //delete content when the page is not refreshed
    } else {
      await deleteCA.deleteCA(oldData);
    }

    resolve();

    setTable((prevState) => {
      const data = [...prevState.data];
      data.splice(data.indexOf(oldData), 1);
      return { ...prevState, data };
    });
  };

  useEffect(() => {
    getTableData();
  }, []);

  useEffect(() => {
    initializeFilterRow(COMMUNICATION_ACTIVITIES_ID, FILTER_ROW_ID);
  }, []);

  return (
    <div id={COMMUNICATION_ACTIVITIES_ID} className="reports-table">
      <MuiThemeProvider theme={tableTheme}>
        <MetaTags title="Communication Activities | Reporting | enRichMyData Project" />

        <MaterialTable
          title="Communication Activities"
          columns={table.columns}
          data={table.data}
          components={{
            Action: props => <TableAction {...props}/>,
            Pagination: PatchedPagination
          }}
          icons={{
            Add: CustomAddButton,
            Export: CustomExportButton
          }}
          actions={[
            {
              icon: "filter_list",
              tooltip: ICONS_TOOLTIP.FILTER,
              isFreeAction: true,
              onClick: () => handleFilterClick(COMMUNICATION_ACTIVITIES_ID, FILTER_ROW_ID)
            }
          ]}
          isLoading={table.isLoading}
          options={{
            filtering: true,
            paging: true,
            paginationType: 'stepped',
            search: false,
            exportMenu: [
              {
                label: "Export PDF",
                exportFunc: (cols) =>
                  ExportPdfTable(cols, table.data, "Communication Activities", ColumnFieldsCA),
              },
              {
                label: "Export CSV",
                exportFunc: (cols) =>
                  ExportCsvTable(cols, table.data, "Communication Activities", ColumnFieldsCA),
              },
            ],
          }}
          editable={{
            onRowAdd: (newData) =>
              new Promise((resolve) => {
                AddTableRow(newData, resolve);
              }),
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve) => {
                UpdateTableRow(newData, oldData, resolve);
              }),
            onRowDelete: (oldData) =>
              new Promise((resolve) => {
                DeleteTableRow(oldData, resolve);
              }),
          }}
        />
      </MuiThemeProvider>
    </div>
  );
}
