import moment from "moment";

import { PartnersList, TargetAudience } from "../data/common";
import { TypeEvent, ColumnFieldsEM } from "../data/DataEM";
import {
  MultiSelectDropdown, FileUploadCell,
  RenderMultiSelectResult, RenderFile,
  CustomSortMultiSelect, 
  CustomFilterMultiSelect, CustomFilterComponent,
  RenderLink
} from "../CustomColumns";
import Validation from "../DataValidation";

const ColumnsEM = [
  { 
    title: "Id", 
    field: ColumnFieldsEM.id, 
    hidden: true 
  },
  { 
    title: "Start Date", 
    field: ColumnFieldsEM.startDate, 
    type: "date",
    render: rowData => moment(rowData[ColumnFieldsEM.startDate]).format('DD/MM/YYYY'),
    validate: Validation.ValidateStartDate.bind(this, ColumnFieldsEM.startDate, ColumnFieldsEM.endDate),
    cellStyle: {
      width: "8%",
      minWidth: 90
    },
  },
  {
    title: "End Date", 
    field: ColumnFieldsEM.endDate, 
    type: "date",
    render: rowData => moment(rowData[ColumnFieldsEM.endDate]).format('DD/MM/YYYY'),
    validate: Validation.ValidateEndDate.bind(this, ColumnFieldsEM.startDate, ColumnFieldsEM.endDate),
    cellStyle: {
      width: "8%",
      minWidth: 90
    },
  },
  { 
    title: "Partners", 
    field: ColumnFieldsEM.partners, 
    lookup: PartnersList,
    editComponent: MultiSelectDropdown.bind(this, PartnersList),
    render: RenderMultiSelectResult.bind(this, "partners", PartnersList),
    customSort: CustomSortMultiSelect.bind(this, "partners", PartnersList),
    customFilterAndSearch: CustomFilterMultiSelect.bind(this, "partners"),
    filterComponent: CustomFilterComponent.bind(this),
    validate: Validation.ValidateMultipleSelect.bind(this, ColumnFieldsEM.partners),
    cellStyle: {
      width: "7%"
    },
  },
  { 
    title: "Type of Event", 
    field: ColumnFieldsEM.eventType, 
    lookup: TypeEvent,
    editComponent: MultiSelectDropdown.bind(this, TypeEvent),
    render: RenderMultiSelectResult.bind(this, "eventType", TypeEvent),
    customSort: CustomSortMultiSelect.bind(this, "eventType", TypeEvent),
    customFilterAndSearch: CustomFilterMultiSelect.bind(this, "eventType"),
    filterComponent: CustomFilterComponent.bind(this),
    validate: Validation.ValidateMultipleSelect.bind(this, ColumnFieldsEM.eventType),
    cellStyle: {
      width: "17%"
    },
  },
  { 
    title: "Event Name", 
    field: ColumnFieldsEM.eventName,
    validate: Validation.ValidateTextField.bind(this, ColumnFieldsEM.eventName),
    cellStyle: {
      width: "10%",
      minWidth: 140
    },
  },
  { 
    title: "Title / Objective / Description", 
    field: ColumnFieldsEM.objective,
    validate: Validation.ValidateTextField.bind(this, ColumnFieldsEM.objective),
    cellStyle: {
      width: "10%",
      minWidth: 180
    },
  },
  { 
    title: "Target Audience", 
    field: ColumnFieldsEM.targetAudience, 
    lookup: TargetAudience,
    editComponent: MultiSelectDropdown.bind(this, TargetAudience),
    render: RenderMultiSelectResult.bind(this, "targetAudience", TargetAudience),
    customSort: CustomSortMultiSelect.bind(this, "targetAudience", TargetAudience),
    customFilterAndSearch: CustomFilterMultiSelect.bind(this, "targetAudience"),
    filterComponent: CustomFilterComponent.bind(this),
    validate: Validation.ValidateMultipleSelect.bind(this, ColumnFieldsEM.targetAudience),
    cellStyle: {
      width: "10%"
    },
  },
  { 
    title: "Reached Audience", 
    field: ColumnFieldsEM.reachedAudience,
    type: "numeric",
    validate: Validation.ValidatePositiveNumber.bind(this, ColumnFieldsEM.reachedAudience),
    cellStyle: {
      width: "8%"
    },
  },
  { 
    title: "Link", 
    field: ColumnFieldsEM.link, 
    render: RenderLink,
    validate: Validation.ValidateLink.bind(this, ColumnFieldsEM.link),
    cellStyle: {
      width: "10%"
    },
  },
  { 
    title: "Photo", 
    field: ColumnFieldsEM.photo,
    editComponent: FileUploadCell.bind(this),
    render: RenderFile.bind(this),
    validate: Validation.ValidateFile.bind(this, ColumnFieldsEM.photo),
    filtering: false,
    cellStyle: {
      width: "12%",
      maxWidth: 210
    },
  }
];

export default ColumnsEM;