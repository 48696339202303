const PartnersList = {
  "sintef": "SINTEF",
  "phi": "PHILIPS",
  "bos": "BOSCH",
  "spn": "SPEND NETWORK",
  "jot": "JOT",
  "csg": "CS GROUP",
  "exp": "EXPERT.AI",
  "ont": "ONTOTEXT",
  "umb": "UNIVERSITY OF MILANO",
  "joz": "JOZEF STEFAN INSTITUTE",
  "uoc": "UNIVERSITY OF COPENHAGEN",
  "gate": "GATE",
  "bgrimm": "BGRIMM"
};

const TargetAudience = {
  "science": "Science",
  "industry": "Industry",
  "education": "Education",
  "policyMaking": "Policy Making",
  "softwareDevelopment": "Software Development",
  "generalPublic": "General Public",
  "customers": "Customers"
};

module.exports = { PartnersList, TargetAudience };