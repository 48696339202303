import React from "react";
import { Link } from "@material-ui/core";
import { NavLink } from "react-router-dom";

export default function NavbarItem(props) {
  return (
    <div className="navbar-item">
      <Link 
        className="main-link"
        variant="inherit"
        color="inherit"
        underline="none"
        component={NavLink}
        activeClassName="selected"
        exact={true}
        to={props.link}
      >
        {props.children}
      </Link>
    </div>
  );
}