const TypeEvent = {
  "organizationWorkshop": "Organisation of a workshop",
  "organizationConference": "Organisation of a conference",
  "collaborationMeeting": "EU project collaboration meeting",
  "exhibition": "Exhibition",
  "tradeFair": "Trade fair",
  "standardisationMeeting": "Standardisation meeting",
  "trainingLecture": "Training/lecture",
  "concertationMeeting": "EC concertation meeting",
  "meetingStakeholders": "Meeting with stakeholders",
  "meetingInterestGroups": "Meeting with Interest Groups",
  "presentationConference": "Presentation in a Conference",
  "presentationWorkshop": "Presentation in a Workshop",
  "presentationPublic": "Presentation in a public event",
  "participationBrokerage": "Participation in a Brokerage event",
  "participationPitch": "Participation in a Pitch Event",
  "other": "Other"
};

const ColumnFieldsEM = {
  id: "id",
  startDate: "startDate",
  endDate: "endDate",
  partners: "partners",
  eventType: "eventType",
  eventName: "eventName",
  objective: "objective",
  targetAudience: "targetAudience",
  reachedAudience: "reachedAudience",
  link: "link",
  photo: "photo"
};

/*************************************************
 ******* MOCK DATA FOR EVENTS AND MEETINGS *******
 *************************************************/

const ExampleData = [
  {
    id: 1,
    startDate: "2020-08-01",
    endDate: "2020-08-02",
    partners: ["jot", "iex"],
    eventType: "projectWorkshops",
    eventName: "Event name 1",
    objective: "Objective 1",
    targetAudience: ["education"],
    reachedAudience: 250,
    link: "https://reactjs.org/docs/hooks-effect.html",
    photo: {
      url: "https://cdn.bulbagarden.net/upload/2/21/001Bulbasaur.png",
      file: null
    }
  },
  {
    id: 2,
    startDate: "2021-05-01",
    endDate: "2021-05-02",
    partners: ["mog", "cer"],
    eventType: "concertationMeeting",
    eventName: "Event name 2",
    objective: "Objective 2",
    targetAudience: ["industry"],
    reachedAudience: 350,
    link: "https://reactjs.org/docs/hooks-effect.html"
  }
];

module.exports = {
  TypeEvent,
  ColumnFieldsEM,
  ExampleData
};