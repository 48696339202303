import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    backgroundColor: "#282D3C",
    borderRadius: "5px",
    color: "#FFFFFF",
    height: 42,
    padding: "0 50px",
    "&:hover": {
      backgroundColor: "#282D3C",
      opacity: 0.8,
    }
  },
  label: {
    fontSize: "14px",
    fontWeight: "500",
    letterSpacing: "2px",
    textTransform: "uppercase"
  },
});

export default function LoginButton(props) {
  const classes = useStyles();

  return (
    <Button
      {...props}
      type="submit"
      fullWidth
      classes={{
        root: classes.root,
        label: classes.label,
      }}
    >
      {props.children}
    </Button>
  );
}