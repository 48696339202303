import React from "react";
import { useDropzone } from "react-dropzone";
import { FormHelperText } from "@material-ui/core";

import ErrorToast from "../errortoast/ErrorToast";
import "./FileUpload.css";

const baseStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flex: "1 0 auto",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#c9c9c9",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#8f8f8f",
  letterSpacing: "0.03em",
  outline: "none",
  transition: "border .24s ease-in-out",
  textAlign: "center",
  height: 100,
  padding: "10px"
};

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  width: "100%",
  justifyContent: "center",
  marginTop: 10
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: "border-box"
};

const thumbInner = {
  display: "flex",
  justifyContent: "center",
  minWidth: 0,
  overflow: "hidden"
};

const img = {
  display: "block",
  width: "auto",
  height: "100%"
};

const getImagePreview = (imageSrc) => {
  return (
    <div style={thumb}>
      <div style={thumbInner}>
        <img src={imageSrc} style={img} alt="Preview" />
      </div>
    </div>
  );
};

export default function FileUpload(props) {
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    multiple: false,
    onDrop: (files) => {
      if (!files || files.length === 0) {
        ErrorToast("Please select a valid file!");
        return;
      }

      let fileInput = files[0];
      fileInput.preview = URL.createObjectURL(fileInput);

      let result = {
        file: fileInput,
        url: ""
      };

      if (props.value && props.value.url) {
        result.url = props.value.url;
      }

      props.onChange(result);
    }
  });

  let thumbs = <></>;

  if (props.value && props.value.file) {
    thumbs = getImagePreview(props.value.file.preview);
  } else if (props.value && props.value.url) {
    thumbs = getImagePreview(window.$apiUrl + props.value.url);
  }

  return (
    <section className="file-container">
      <div {...getRootProps({ className: "dropzone", style: baseStyle })}>
        <input {...getInputProps()} />
        <p>Drag &amp; drop file here...<br/> or click to select file</p>
      </div>
      <div className="file-error-container">
      {Boolean(props.helperText) && 
        <FormHelperText className="file-error-text">{props.helperText}</FormHelperText>}
      </div>
      <aside style={thumbsContainer}>{thumbs}</aside>
    </section>
  );
}