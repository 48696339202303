import { combineReducers } from "redux";
import { ACTION_TYPES } from "./actions";

const userReducer = (state = null, action) => {
  switch (action.type) {
    case ACTION_TYPES.USER.UPDATE_LOGIN_STATE:
      return action.payload;
    default:
      return state;
    }
};

const reducer = combineReducers({
  loginState: userReducer
});

export default reducer;
