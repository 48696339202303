import { ThemeProvider, CssBaseline } from "@material-ui/core";
import smoothscroll from "smoothscroll-polyfill";
import { ToastContainer } from "react-toastify";
import { Provider } from "react-redux";

import { theme } from "./templates/themes";
import store from "./redux/store";
import AppRouter from "./templates/approuter/AppRouter";
import { HelmetProvider } from "react-helmet-async";

import "react-toastify/dist/ReactToastify.min.css";

function App() {
  smoothscroll.polyfill();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      
      <HelmetProvider>
        <Provider store={store}>
          <AppRouter />
        </Provider>
      </HelmetProvider>
      <ToastContainer />
    </ThemeProvider>
  );
}

export default App;
