import moment from "moment";
import { CsvBuilder } from "filefy";
import "jspdf-autotable";

import { PartnersList, TargetAudience } from "./data/common";
import { CommunicationType, ColumnFieldsCA } from "./data/DataCA";
import { TypeEvent, ColumnFieldsEM } from "./data/DataEM";
import { PublicationType, ColumnFieldsSP } from "./data/DataSP";

const GetSelectedValuesAsText = (dropdownOptions, selectedValues) => {
  const values = selectedValues.map((value) => {
    return dropdownOptions[value];
  });

  return values.join(", ");
}

const GetDropdownOptions = (key) => {
  let dropdownOptions;
  switch (key) {
    case ColumnFieldsCA.partners:
      dropdownOptions = PartnersList;
      break;
    case ColumnFieldsCA.communicationType:
      dropdownOptions = CommunicationType;
      break;
    case ColumnFieldsCA.targetAudience:
      dropdownOptions = TargetAudience;
      break;
    case ColumnFieldsEM.eventType:
      dropdownOptions = TypeEvent;
      break;
    case ColumnFieldsSP.publicationType:
      dropdownOptions = PublicationType;
      break;
    default:
      console.error(`  GetDropdownOptions --> Invalid key provided: ${key}`);
  }

  return dropdownOptions;
}

const GetRowValuesAsText = (row, columnfields, yeardate) => {
  let rowValues = {};

  for (const key in columnfields) {
    if (key === "id" || key === "photo") continue;

    let value = row[key];

    if (yeardate && key === "date") {
      value = moment(value).format("YYYY");
    } else if (key === "date" || key === "startDate" || key === "endDate") {
      value = moment(value).format("DD/MM/YYYY");
    }

    if (Array.isArray(value)) {
      const dropdownOptions = GetDropdownOptions(key);
      const selectedValues = GetSelectedValuesAsText(dropdownOptions, value);

      rowValues[key] = selectedValues;
    } else rowValues[key] = value;
  }

  return Object.values(rowValues);
}

const RemovePhotoField = (columns) => {
  const colIndex = columns.findIndex(x => x.field === "photo");
  if (colIndex !== -1) { columns.splice(colIndex, 1); }

  return columns;
}

const getFinalData = (data = [], columnfields, yeardate) => {
  let finalData = data;
    
  if (data.length && !Array.isArray(data[0])) {
    if (typeof data[0] === "object") {
      // Turn data into an array of string arrays, without the `tableData` prop
      finalData = data.map(({ id, tableData, photo, ...row }) => {
        const values = GetRowValuesAsText(row, columnfields, yeardate);
        return values;
      });
    }
  }

  return finalData;
}

const ExportCsvTable = (columns, data = [], filename = "data", columnfields, yeardate = false, delimiter = ",") => {
  try {
    const finalData = getFinalData(data, columnfields, yeardate);
    const cols = RemovePhotoField(columns);
    
    const builder = new CsvBuilder(filename + ".csv");
    builder
      .setDelimeter(delimiter)
      .setColumns(cols.map((col) => col.title))
      .addRows(Array.from(finalData))
      .exportFile();
  } catch (err) {
    console.error(` Error in ExportCsv --> ${err}`);
  }
};

const ExportPdfTable = (columns, data = [], filename = "data", columnfields, yeardate = false) => {
  const JSpdf = typeof window !== 'undefined' ? require('jspdf').jsPDF : null;

  const finalData = getFinalData(data, columnfields, yeardate);
  const cols = RemovePhotoField(columns);

  if (JSpdf !== null) {
    const content = {
      startY: 50,
      head: [cols.map((col) => col.title)],
      body: finalData
    };
    const unit = 'pt';
    const size = 'A4';
    const orientation = 'landscape';
    const doc = new JSpdf(orientation, unit, size);
    doc.setFontSize(15);
    doc.text(filename, 40, 40);
    doc.autoTable(content);
    doc.save(filename + '.pdf');
  }
}

export { ExportCsvTable, ExportPdfTable };