import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import {
  Checkbox,
  FormControl,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Select
} from "@material-ui/core";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const useStyles = makeStyles({
  formControl: {
    maxWidth: 300
  },
  root: {
    fontSize: "14px !important"
  },
  selectMenu: {
    padding: "8px 15px",
  }
});

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left"
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left"
  }
};

function LookupFilter(props) {
  const classes = useStyles();

  const [selectedFilter, setSelectedFilter] = useState(
    props.columnDef.tableData.filterValue || []
  );

  useEffect(() => {
    setSelectedFilter(props.columnDef.tableData.filterValue || []);
  }, [props.columnDef.tableData.filterValue]);

  return (
    <FormControl 
      classes={{ root: classes.formControl }}
      style={{ width: "100%" }} 
      ref={props.forwardedRef}
    >
      <InputLabel
        htmlFor={"select-multiple-checkbox" + props.columnDef.tableData.id}
        style={{ marginTop: -16 }}
      >
        {props.columnDef.filterPlaceholder}
      </InputLabel>
      <Select
        classes={{ root: classes.root }}
        multiple
        value={selectedFilter}
        onClose={() => {
          if (props.columnDef.filterOnItemSelect !== true) {
            props.onFilterChanged(props.columnDef.tableData.id, selectedFilter);
          }
        }}
        onChange={(event) => {
          setSelectedFilter(event.target.value);
          if (props.columnDef.filterOnItemSelect === true) {
            props.onFilterChanged(props.columnDef.tableData.id, event.target.value);
          }
        }}
        input={
          <Input id={"select-multiple-checkbox" + props.columnDef.tableData.id} />
        }
        renderValue={(selectedArr) =>
          selectedArr.map((selected) => props.columnDef.lookup[selected]).join(", ")
        }
        MenuProps={MenuProps}
        style={{ marginTop: 0 }}
      >
        {Object.keys(props.columnDef.lookup).map((key) => (
          <MenuItem
            classes={{ root: classes.selectMenu }}
            key={key}
            value={key}
          >
            <Checkbox checked={selectedFilter.indexOf(key.toString()) > -1} />
            <ListItemText
              classes={{ primary: classes.root }}
              primary={props.columnDef.lookup[key]} 
            />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default React.forwardRef(function LookupFilterRef(props, ref) {
  return <LookupFilter {...props} forwardedRef={ref} />;
});