const PublicationType = {
  "conferencePaper": "Conference Paper",
  "journalArticle": "Journal Article",
  "journalSpecialIssue": "Journal Special Issue",
  "openData": "Open Data",
  "openSourceSoftware": "Open Source Software",
  "tutorial": "Tutorial",
  "other": "Other" 
};

const ColumnFieldsSP = {
  id: "id",
  date: "date",
  partners: "partners",
  publicationType: "publicationType",
  authors: "authors",
  publicationTitle: "publicationTitle",
  journalName: "journalName",
  publicationDOI: "publicationDOI",
  zenodoLink: "zenodoLink",
  targetAudience: "targetAudience",
  photo: "photo"
};

/*****************************************************
 ******* MOCK DATA FOR SCIENTIFIC PUBLICATIONS *******
 *****************************************************/

const ExampleData = [
  {
    id: 1,
    date: "2020-08-01",
    partners: ["sintef", "uro"],
    publicationType: "conferencePaper",
    targetAudience: ["science"],
    citation: "Example citation.",
    link: "https://reactjs.org/docs/hooks-effect.html",
    photo: {
      url: "https://cdn.bulbagarden.net/upload/2/21/001Bulbasaur.png",
      file: null
    }
  },
  {
    id: 2,
    date: "2021-08-01",
    partners: ["mog"],
    publicationType: "journalArticle",
    targetAudience: ["policyMaking"],
    citation: "2nd example of citation.",
    link: "https://material-table.com/",
    photo: {
      url: "https://cdn.bulbagarden.net/upload/thumb/b/bf/152Chikorita.png/1200px-152Chikorita.png",
      file: null
    }
  },
  {
    id: 3,
    date: "2021-09-01",
    partners: ["aau"],
    publicationType: "tutorial",
    targetAudience: ["education"],
    citation: "3rd example of citation.",
    link: "https://www.google.com/"
  }
];

module.exports = {
  PublicationType, ColumnFieldsSP,
  ExampleData
};