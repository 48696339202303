import React, { Component } from "react";
import { Helmet } from "react-helmet-async";

export default class MetaTags extends Component {
  render() {
    let title = "enRichMyData | Reporting Tool";
    let description = "enRichMyData project aims to deliver innovative pipelines for building " +
    "rich, high-quality, added-value datasets to feed Big Data Analytics and AI applications.";

    if (this.props.title) { title = this.props.title; }
    if (this.props.description) { description = this.props.description; }

    return (
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
        <meta 
          name="description" 
          content={description}
        />
        <meta name="robots" content="index, follow" />
      </Helmet>
    );
  }
}
