import React from "react";
import { Link } from "@material-ui/core";
import FileUpload from "../../components/fileupload/FileUpload";
import MultipleSelect from "../../components/multiselect/MultiSelect";
import LookupFilter from "../../components/lookupfilter/LookupFilter";
import YearDatePicker from "../../components/yeardatepicker/YearDatePicker";

import DefaultImg from "../../assets/images/default-image.png";

/***********************************************
 ***************** CUSTOM SORT *****************
 ***********************************************/

const CustomSortMultiSelect = (field, dropdownOptions, data1, data2) => {
  const values1 = data1[field].map((value) => {
    return dropdownOptions[value];
  });

  const values2 = data2[field].map((value) => {
    return dropdownOptions[value];
  });

  const a = values1.join(", ");
  const b = values2.join(", ");

  if(a < b) { return -1; }
  if(a > b) { return 1; }

  return 0;
}

/***********************************************
 **************** CUSTOM FILTER ****************
 ***********************************************/

const CustomFilterComponent = (props) => {
  return (<LookupFilter {...props} />);
}

/************************************************
 *********** CUSTOM FILTER AND SEARCH ***********
 ************************************************/

const CustomFilterMultiSelect = (field, input, rowData) => {
  if (input.length === 0) { return true; }

  const rowOptions = rowData[field];
  const found = rowOptions.some(r => input.includes(r));

  return found;
}

/************************************************
 ************ CUSTOM EDIT COMPONENTS ************
 ************************************************/

const MultiSelectDropdown = (dropdownOptions, fieldProps) => {
  return (
    <MultipleSelect
      {...fieldProps}
      lookup={dropdownOptions}
    />
  );
}

const FileUploadCell = (props) => {
  return (<FileUpload {...props} />);
}

const YearPicker = (props) => {
  return (<YearDatePicker {...props} />);
}

/***********************************************
 *********** CUSTOM RENDER FUNCTIONS ***********
 ***********************************************/

 const RenderMultiSelectResult = (field, dropdownOptions, rowData) => {
  const values = rowData[field].map((value) => {
    return dropdownOptions[value];
  });

  const list = (
    <ul>
      {values.map((value) => <li key={value}>{value}</li>)}
    </ul>
  )

  return list;
}

const RenderFile = (rowData) => {
  const fileExists = (rowData.photo === undefined);
  let fileURL = "";
  if (rowData.photo) {
    if (rowData.photo.url) { fileURL = window.$apiUrl + rowData.photo.url;}
    else {
      if (rowData.photo.file) { fileURL = rowData.photo.file.preview;}
    }
  }
  
  return fileExists ? (
    <div className="table-photo">
      <img
        src={DefaultImg}
        width="150"
        alt="Default"
      />
    </div>
  ) : (
    <div className="table-photo">
      <img src={fileURL} alt="Table Row" width="150" />
    </div>
  )
}

const RenderLink = (rowData) => {
  return <Link href={rowData.link} target="_blank">{rowData.link}</Link>;
}

const RenderCustomLink = (field, data) => {
  if (data[field] === undefined) { return <></>; }
  else return <Link href={data[field]} target="_blank">{data[field]}</Link>;
}

const RenderDOI = (field, data) => {
  const prefix = "https://www.doi.org/";
  const link = prefix + data[field];
  if (data[field] === undefined) { return <></>; }
  else return <Link href={link} target="_blank">{data[field]}</Link>;
}

export { 
  CustomSortMultiSelect, CustomFilterMultiSelect, CustomFilterComponent,
  MultiSelectDropdown, FileUploadCell, YearPicker,
  RenderMultiSelectResult, RenderFile,
  RenderLink, RenderCustomLink, RenderDOI
};