const NavbarOffset = 70;

export const scrollTo = (offset, smooth) => {
  let scrollOptions = { 
    top: offset 
  };
  if (smooth) { scrollOptions.behavior = "smooth"; }

  window.scrollTo(scrollOptions);
}

export const scrollWithOffset = (el) => {
  const elementPosition = el.getBoundingClientRect()?.top;
  const yCoordinate = elementPosition + window.pageYOffset;

  const yOffset = -1 * NavbarOffset;
  const offset = yCoordinate + yOffset;

  scrollTo(offset, false);
}

export const validURL = (str) => {
  let url;
  
  try {
    url = new URL(str);
  } catch (_) {
    return false;  
  }

  return url.protocol === "http:" || url.protocol === "https:";
}

export const getTokenJWT = () => {
  const token = localStorage.getItem(window.$userJwt);
  const AUTH_HEADER = "Bearer " + token;
  return AUTH_HEADER;
}