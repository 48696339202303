import { unstable_createMuiStrictModeTheme as createMuiTheme, responsiveFontSizes } from "@material-ui/core";

export let theme = responsiveFontSizes(
  createMuiTheme({
    typography: {
      fontFamily: ["aileron", "sans-serif"].join(","),
    },
    palette: {
      background: {
        default: "#FFFFFF",
      },
      common: {
        black: "#000000",
      },

      primary: {
        main: "#6EC1E4",
      },
      secondary: {
        main: "#282D3C",
      },

      error: {
        main: "#ff7070",
      },

      text: {
        primary: "#282D3C",
        secondary: "#FFFFFF",
      }
    },
    overrides: {
      /* Input Label Customization */
      MuiInputLabel: {
        filled: {
          color: "#999999",
          letterSpacing: "1px",
          fontWeight: "300",
        }
      },
      /* Text Field Input Customization */
      MuiFilledInput: {
        root: {
          /* Remove round borders */
          borderTopLeftRadius: "0px",
          borderTopRightRadius: "0px",
          /* White background on hover and focus */
          backgroundColor: "white",
          "&:hover": {
            backgroundColor: "white",
          },
          "&$focused": {
            backgroundColor: "white",
          }
        },
        focused: {}
      }
    }
  })
);

export let tableTheme = createMuiTheme({
  typography: {
    fontFamily: ["aileron", "sans-serif"].join(","),
    color: "#000000",
    fontSize: 14,
  },
  palette: {
    common: {
      black: "#000000",
    },

    primary: {
      main: "#282D3C",
    },
    secondary: {
      main: "#000000",
    },

    error: {
      main: "#ff7070",
    },

    text: {
      primary: "#000000",
    }
  },
  overrides: {
    MuiTypography: {
      h6: {
        color: "#003961",
        textTransform: "uppercase",
        fontWeight: "bold",
      }
    },
    MuiTableSortLabel: {
      root: {
        color: "#003961",
        fontSize: 15,
        letterSpacing: "0.03em",
        fontWeight: "bold",
        flexDirection: "row",
        "&>div": {
          textAlign: "left"
        },
        "&:hover": {
          color: "#282D3C",
        },
        "@media(max-width: 1440px)": {
          fontSize: 14
        },
      },
      active: {
        color: "#282D3C",
      },
    },
    MuiFormControl: {
      root: {
        width: "100%"
      }
    },
    MuiIcon: {
      root: {
        color: "rgba(0, 0, 0, 0.54)"
      }
    },
    MuiMenuItem: {
      root: {
        fontSize: 14,
        padding: "8px 15px !important"
      }
    },
    MuiInputBase: {
      root: {
        fontSize: 14
      }
    },
    MuiTableCell: {
      root: {
        padding: "10px 8px",
        "@media(max-width: 1440px)": {
          padding: "10px 5px",
          fontSize: "13px !important"
        },
      },
      paddingNone: {
        paddingTop: "1em !important",
        paddingBottom: "1em !important",
      }
    },
    MuiFormHelperText: {
      root: {
        color: "#008000"
      }
    }
  },
});