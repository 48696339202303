export const ACTION_TYPES = {
	USER: {
		UPDATE_LOGIN_STATE: "UPDATE_LOGIN_STATE",
	},
};

export class UserActions {
  static updateLoginState = (state) => ({
    type: ACTION_TYPES.USER.UPDATE_LOGIN_STATE,
    payload: state,
  });
}