const CommunicationType = {
  "pressRelease": "Press Release",
  "projectVideos": "Project Videos",
  "enrichmydataVideo": "enRichMyData Video",
  "businessCaseVideo": "Business Case Video",
  "socialMediaPost": "Social media post",
  "blogPost": "Blog post",
  "newsArticle": "News Article/Interview",
  "factSheet": "Fact Sheet/Flyer",
  "communicationCampaign": "Communication Campaign",
  "other": "Other" 
};

const ColumnFieldsCA = {
  id: "id",
  date: "date",
  partners: "partners",
  communicationType: "communicationType",
  title: "title",
  objective: "objective",
  targetAudience: "targetAudience",
  reachedAudience: "reachedAudience",
  link: "link",
  photo: "photo"
};

/******************************************************
 ******* MOCK DATA FOR COMMUNICATION ACTIVITIES *******
 ******************************************************/

const ExampleData = [
  {
    id: 1,
    date: "2020-08-01",
    partners: ["ubi", "tlu"],
    communicationType: "pressRelease",
    title: "1st example of title",
    objective: "Objective 1",
    targetAudience: ["science"],
    reachedAudience: 200,
    link: "https://reactjs.org/docs/hooks-effect.html"
  },
  {
    id: 2,
    date: "2019-06-01",
    partners: ["bos", "cer"],
    communicationType: "socialMediaPost",
    title: "2nd example of title",
    objective: "Objective 2",
    targetAudience: ["policyMaking", "softwareDevelopment"],
    reachedAudience: 300,
    link: "https://material-table.com/"
  },
  {
    id: 3,
    date: "2018-03-01",
    partners: ["jot"],
    communicationType: "socialMediaPost",
    title: "2nd example of title",
    objective: "Objective 3",
    targetAudience: ["education"],
    reachedAudience: 200,
    link: "https://www.google.com/"
  }
];

module.exports = {
  CommunicationType,
  ColumnFieldsCA,
  ExampleData
};