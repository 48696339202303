import moment from "moment";

import { PartnersList, TargetAudience } from "../data/common";
import { CommunicationType, ColumnFieldsCA } from "../data/DataCA";
import Validation from "../DataValidation";
import {
  MultiSelectDropdown, FileUploadCell,
  RenderMultiSelectResult, RenderFile,
  CustomSortMultiSelect, 
  CustomFilterMultiSelect, CustomFilterComponent,
  RenderLink 
} from "../CustomColumns";

const ColumnsCA = [
  { 
    title: "Id", 
    field: ColumnFieldsCA.id, 
    hidden: true 
  },
  { 
    title: "Date", 
    field: ColumnFieldsCA.date, 
    type: "date",
    render: rowData => moment(rowData[ColumnFieldsCA.date]).format('DD/MM/YYYY'),
    validate: Validation.ValidateDate.bind(this, ColumnFieldsCA.date),
    cellStyle: {
      width: "8%",
    }
  },
  { 
    title: "Partners", 
    field: ColumnFieldsCA.partners, 
    lookup: PartnersList,
    editComponent: MultiSelectDropdown.bind(this, PartnersList),
    render: RenderMultiSelectResult.bind(this, ColumnFieldsCA.partners, PartnersList),
    customSort: CustomSortMultiSelect.bind(this, ColumnFieldsCA.partners, PartnersList),
    customFilterAndSearch: CustomFilterMultiSelect.bind(this, ColumnFieldsCA.partners),
    filterComponent: CustomFilterComponent.bind(this),
    validate: Validation.ValidateMultipleSelect.bind(this, ColumnFieldsCA.partners),
    cellStyle: {
      width: "10%",
    }
  },
  { 
    title: "Type of Communication", 
    field: ColumnFieldsCA.communicationType, 
    lookup: CommunicationType,
    filterComponent: CustomFilterComponent.bind(this),
    validate: Validation.ValidateSelect.bind(this, ColumnFieldsCA.communicationType),
    cellStyle: {
      width: "16%"
    }
  },
  { 
    title: "Title", 
    field: ColumnFieldsCA.title,
    validate: Validation.ValidateCapitalizedInput.bind(this, ColumnFieldsCA.title),
    cellStyle: {
      width: "11%"
    }
  },
  { 
    title: "Objective / Description", 
    field: ColumnFieldsCA.objective,
    validate: Validation.ValidateTextField.bind(this, ColumnFieldsCA.objective),
    cellStyle: {
      width: "13%",
    }
  },
  { 
    title: "Target Audience", 
    field: ColumnFieldsCA.targetAudience, 
    lookup: TargetAudience,
    editComponent: MultiSelectDropdown.bind(this, TargetAudience),
    render: RenderMultiSelectResult.bind(this, ColumnFieldsCA.targetAudience, TargetAudience),
    customSort: CustomSortMultiSelect.bind(this, ColumnFieldsCA.targetAudience, TargetAudience),
    customFilterAndSearch: CustomFilterMultiSelect.bind(this, ColumnFieldsCA.targetAudience),
    filterComponent: CustomFilterComponent.bind(this),
    validate: Validation.ValidateMultipleSelect.bind(this, ColumnFieldsCA.targetAudience),
    cellStyle: {
      width: "12%",
    }
  },
  { 
    title: "Reached Audience", 
    field: ColumnFieldsCA.reachedAudience,
    type: "numeric",
    validate: Validation.ValidatePositiveNumber.bind(this, ColumnFieldsCA.reachedAudience),
    cellStyle: {
      width: "8%",
    }
  },
  { 
    title: "Link", 
    field: ColumnFieldsCA.link, 
    render: RenderLink,
    validate: Validation.ValidateLink.bind(this, ColumnFieldsCA.link),
    cellStyle: {
      width: "12%",
    }
  },
  { 
    title: "Photo", 
    field: ColumnFieldsCA.photo,
    editComponent: FileUploadCell.bind(this),
    render: RenderFile.bind(this),
    validate: Validation.ValidateFile.bind(this, ColumnFieldsCA.photo),
    filtering: false,
    cellStyle: {
      width: "10%",
      maxWidth: 210
    }
  }
];

export default ColumnsCA;