import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import ReportsLayout from "./ReportsLayout";
import ScrollToTop  from "../../components/scrolltotop/ScrollToTop";

export default class AppRouter extends Component {
  render() {
    return (
      <Router>
        <ScrollToTop />
        <Switch>
          <Route path="/">
            <ReportsLayout />
          </Route>
        </Switch>
      </Router>
    );
  }
}
