import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect, Switch, Route } from "react-router-dom";

import NavbarReports from "../navbar/NavbarReports";
import Login from "../../pages/login/Login";
import ScientificPublications from "../../pages/reports/TableSP";
import CommunicationActivities from "../../pages/reports/TableCA";
import EventsMeetings from "../../pages/reports/TableEM";
import { UserActions } from "../../redux/actions";

class ReportsLayout extends Component { 

  componentDidMount() {
    if (localStorage.getItem(window.$userJwt)) { 
      this.props.updateLoginState(true); 
    } else {
      this.props.updateLoginState(false); 
    }
  }

  render() {
    if (this.props.loginState === null) {
      return <></>;
    }

    if (!this.props.loginState) {
      return (
				<Switch>
					<Route exact path="/" component={Login}/>
          <Redirect to="/" />
				</Switch>
      );
    }

    return (
      <div>
        <NavbarReports />
        <main>
          <Switch>
            <Route exact path="/scientific-publications" component={ScientificPublications} />
            <Route exact path="/communication-activities" component={CommunicationActivities} />
            <Route exact path="/events-meetings" component={EventsMeetings}/>

            <Redirect to="/scientific-publications" />
          </Switch>
        </main>

      </div>
    );
  }
}

const mapStateToProps = (state) => ({
	loginState: state.loginState,
});

const mapActionsToProps = {
	updateLoginState: UserActions.updateLoginState
};

export default connect(mapStateToProps, mapActionsToProps)(ReportsLayout);
